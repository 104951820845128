var courseModule = angular.module('t2g.course', [
    'gettext',
    'ui.router',
    require('t2g.common.services.modalService').name,
    require('t2g.common.services.notificationService').name
]);

module.exports = courseModule;

courseModule.controller('CourseListCtrl', require('./list/listCtrl.js'));
courseModule.controller('CourseModalCtrl', require('./modal/courseModalCtrl.js'));

courseModule.config(function($stateProvider) {
    $stateProvider
        .state('private.main.courses', {
            url: '/courses',
            title: 'Courses',
            templateUrl: 'https://cdn.termin2go.com/app/app/modules/course/list/CourseListView.html?v=3.82.0',
            controller: 'CourseListCtrl',
            resolve: {
                officeResolve: function(OfficeDA) {
                    return OfficeDA.get();
                }
            }
        });
});


var PageWidgetCtrl = function ($scope,
                               $sce,
                               $timeout,
                               OfficeDA,
                               office,
                               settings,
                               gettextCatalog,
                               Notification) {
  var self = this;

  this._sce = $sce;
  this.showPremiumNotification = $scope.subscription.onlyBasicFunctions;
  this.office = office;
  this._settings = settings;
  this.widgetHtmlCode = '';
  this._OfficeDA = OfficeDA;
  this._Notification = Notification;
  this.gettextCatalog = gettextCatalog;
  this._init();
  this.iframeVisible = true;
  this.stringOverwrites = {
    'select_services': gettextCatalog.getString('Select your services'),
    'services': gettextCatalog.getString('services'),
    'appointment': gettextCatalog.getString('appointment'),
    'contact': gettextCatalog.getString('contact'),
    'forward': gettextCatalog.getString('forward'),
    'back': gettextCatalog.getString('back'),
    'unspecific_employee': gettextCatalog.getString('unspecific employee'),
    'please_select': gettextCatalog.getString('Please select'),
    'please_select_employee_first': gettextCatalog.getString('Please select an Employee first'),
    'book_appointment': gettextCatalog.getString('book appointment'),
    'congratulations': gettextCatalog.getString('Congratulations! You successfully booked an appointment. Please await the appointment confirmation from the service provider.'),
    'check_spam': gettextCatalog.getString('Please also check your spam folder emails!'),
    'book_another_appointment': gettextCatalog.getString('Book another appointment at {{SuccessCtrl.office.name}}'),
    'no_appointments_free': gettextCatalog.getString('No free appointments in next 6 weeks'),
  };
  this.bookingUrl = this.office.profile.bookingUrl;
  this.walkInBookingUrl = this.office.profile.walkInBookingUrl;

  $scope.$watch(function () {
    return office.settings.widget.appointment.autoHeight;
  }, this.generateWidgetCode.bind(self), true);
};

PageWidgetCtrl.prototype.getEncodedUrl = function(url) {
  return encodeURIComponent(url);
}

PageWidgetCtrl.prototype._init = function () {
  if (!this.office.settings.widget || !this.office.settings.widget.appointment || !this.office.settings.widget.appointment.primary || !this.office.settings.widget.appointment.primaryContrast) {
    this.revert();
  }
};

PageWidgetCtrl.prototype.generateWidgetCode = function () {
  var widgetCode = '';

  if (this.office.settings.widget.appointment.autoHeight) {
    widgetCode += `<iframe src="${this.office.profile.bookingUrl}" frameBorder="0" height="300px" width="100%" scrolling="yes" id="t2g-booking" allowpaymentrequest></iframe>
<script>var bookingFrame = document.getElementById('t2g-booking');
var receiveMessage = function (event) {
  if (event.origin === '${this._settings.urls.booking}') {
    if (bookingFrame && event.data.type === 'heightChanged') {
      console.log(event.data.height);
      bookingFrame.height = event.data.height + 50;
    }
  }
};
window.addEventListener('message', receiveMessage, false);</script>`;
  } else {
    widgetCode += `<iframe src="${this.office.profile.bookingUrl}" frameBorder="0" height="560px" width="100%" scrolling="yes" id="t2g-booking" allowpaymentrequest></iframe>`;
  }

  this.widgetHtmlCode = this._sce.trustAsHtml(widgetCode);
};

PageWidgetCtrl.prototype.save = function () {
  var self = this;

  this.iframeVisible = false;
  this._OfficeDA
    .update(this.office)
    .then(() => {
      self._Notification.addSuccessMessage(self.gettextCatalog.getString('Widget settings saved'));
    })
    .catch((error) => {
      self._Notification.addErrorMessage({text: error});
    })
    .finally(() => {
      self.iframeVisible = true;
    });
};

PageWidgetCtrl.prototype.revert = function () {
  this.office.settings.widget = {
    appointment: {
      bg: '#fff',
      primary: '#4caf50',
      primaryContrast: '#ffffff',
      serviceGroupHeaderBg: '#f5f5f5',
      textColor: '#333',
      secondaryTextColor: '#bdbdbd',
      bottomBarBg: '#333',
      bottomBarTextColor: '#fff'
    }
  };
};

module.exports = PageWidgetCtrl;

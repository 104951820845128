'use strict';

var customerModule = angular.module('t2g.customer', [
    'gettext',
    'ngFileUpload',
    'ui.router',
    require('t2g.common.data-access.customer').name,
    require('t2g.common.services.industrySpecificService').name,
    require('t2g.common.services.modalService').name,
    require('t2g.common.services.notificationService').name,
    require('t2g.common.services.sessionStorage').name
]);

module.exports = customerModule;

customerModule.controller('CustomerProfileCtrl', require('./profile/CustomerProfileCtrl'));
customerModule.controller('CustomerUploadCtrl', require('./upload/CustomerUploadCtrl'));
customerModule.controller('CustomerListCtrl', require('./CustomerListCtrl'));
customerModule.controller('CustomerMergeCtrl', require('./merge/CustomerMergeCtrl'));

customerModule.config(function ($stateProvider) {
    $stateProvider
      .state('private.main.customer', {
          url: '/customers',
          title: 'Customers',
          templateUrl: 'https://cdn.termin2go.com/app/app/modules/customer/list.html?v=3.82.0',
          controller: 'CustomerListCtrl as CustomerListCtrl',
          resolve: {
              customers: function (CustomerDA) {
                  return CustomerDA.list();
              }
          }
      })
      .state('private.main.customerUpload', {
          url: '/customerUpload',
          title: 'Customers',
          templateUrl: 'https://cdn.termin2go.com/app/app/modules/customer/upload/CustomerUploadView.html?v=3.82.0',
          controller: 'CustomerUploadCtrl'
      })
      .state('private.main.merge', {
          url: '/merge/:customerIds',
          title: 'Customers',
          templateUrl: 'https://cdn.termin2go.com/app/app/modules/customer/merge/MergeView.html?v=3.82.0',
          controller: 'CustomerMergeCtrl as CustomerMergeCtrl',
          resolve: {
              customers: function ($stateParams, CustomerDA) {
                return CustomerDA.get($stateParams.customerIds);
              },
              office: function (OfficeDA) {
                  return OfficeDA.get();
              }
          }
      })
      .state('private.main.customerProfile', {
          url: '/customers/:customerId?referrer&tab&egkData',
          params: {
              referrer: {
                  dynamic: true
              },
              tab: {
                  dynamic: true
              }
          },
          title: 'Customers',
          templateUrl: 'https://cdn.termin2go.com/app/app/modules/customer/profile/partial-customer-profile.html?v=3.82.0',
          controller: 'CustomerProfileCtrl',
          resolve: {
              customer: function ($stateParams,
                                  $state,
                                  CustomerDA) {
                  if ($stateParams.customerId && $stateParams.customerId !== 'new') {
                      return CustomerDA
                        .get($stateParams.customerId)
                        .catch(() => {
                          $state.go('private.main.customer');
                        });
                  } else {
                      return {};
                  }

              },
              filteredCustomers: function (CustomerDA) {
                  return CustomerDA.getFiltered();
              },
              office: function (OfficeDA) {
                  return OfficeDA.get();
              },
              resources: function (ResourceDA) {
                  return ResourceDA
                    .list('employee')
                    .then(function (employees) {
                        return _.keyBy(employees.resources, '_id');
                    });

              },
              services: function (OfficeDA) {
                  let services = [];

                  return OfficeDA
                    .get()
                    .then((office) => {
                        _.forEach(office.serviceGroups, function (group) {
                            _.forEach(group.services, function (service) {
                                service.group = group.name;
                            });

                            services = services.concat(group.services);
                        });

                        return services;
                    });
              },
              invoices: function ($stateParams, AccountingDA) {
                  if ($stateParams.customerId && $stateParams.customerId !== 'new') {
                      return AccountingDA.getForCustomer($stateParams.customerId);
                  } else {
                      return [];
                  }
              }
          }
      });
});

var accountModule = angular.module('t2g.account', [
  'ui.router'
]);

module.exports = accountModule;

accountModule.controller('AccountCtrl', require('./AccountCtrl'));

accountModule.config(function ($stateProvider) {
  $stateProvider
    .state('private.main.account', {
      url: '/account/?:openTab',
      params: {
        openTab: {
          dynamic: true
        }
      },
      title: 'Account',
      templateUrl: 'https://cdn.termin2go.com/app/app/modules/account/AccountView.html?v=3.82.0',
      controller: 'AccountCtrl as AccountCtrl',
      resolve: {
        user(UserSession) {
          return UserSession.get();
        }
      }
    })
    .state('private.main.logout', {
      url: '/logout',
      template: '',
      resolve: {
        logout: function (UserDA) {
          return UserDA.logout();
        }
      }
    });
});

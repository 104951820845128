'use strict';

var holidaysModule = angular.module('t2g.holidays', [
  'gettext',
  'ui.router',
  require('t2g.common.services.notificationService').name
]);

var today = new Date();

module.exports = holidaysModule;

holidaysModule.config(function ($stateProvider) {
  $stateProvider
    .state('private.main.holidays', {
      url: '/holidays',
      title: 'Holidays',
      templateUrl: 'https://cdn.termin2go.com/app/app/modules/holidays/HolidaysView.html?v=3.82.0',
      controller: 'holidaysCtrl',
      resolve: {
        holidaysResponse: function (OfficeDA, CountryConfigResource) {
          const holidaysResponse = {};
          const currentYear = moment().year();
          let office;

          return OfficeDA
            .get()
            .then((response) => {
              office = response;
              return CountryConfigResource.get({countryCode: office.address.countryCode, years: currentYear}).$promise;
            })
            .then((response) => {
              angular.forEach(response.countryConfig.holidays, function (days, year) {
                angular.forEach(response.countryConfig.holidays[year], function (holiday, date) {
                  holiday.date = new Date(holiday.date);
                  response.countryConfig.holidays[year][date].key = holiday.date.getDayKey();
                });
              });
              response.office = office;
              return response;
            });
        }
      }
    });
});

holidaysModule.controller('holidaysCtrl', function ($scope,
                                                    gettextCatalog,
                                                    Notification,
                                                    LoadingIndicatorService,
                                                    OfficeDA,
                                                    CountryConfigResource,
                                                    holidaysResponse,
                                                    DatepickerSettings) {


  $scope.office = holidaysResponse.office;
  $scope.office.openingHours.special = $scope.office.openingHours.special || {};
  $scope.countryConfig = holidaysResponse.countryConfig;
  $scope.holidays = {};
  $scope.currentYear = today.getFullYear();
  $scope.years = [$scope.currentYear, $scope.currentYear + 1, $scope.currentYear + 2];
  $scope.dtpOptions = DatepickerSettings.default;
  $scope.dtpOptions.maxMode = 'month';
  $scope.newHoliday = {
    name: '',
    date: new Date($scope.currentYear, 0, 1)
  };
  $scope.officeClosing = {
    start: moment().add(1, 'day').startOf('day').toDate(),
    end: moment().add(2, 'day').startOf('day').toDate()
  };

  $scope.$watch('officeClosing', function () {
    var start = moment($scope.officeClosing.start).startOf('day');
    var end = moment($scope.officeClosing.end).startOf('day');

    if (start.isAfter(end)) {
      $scope.dtpOptionsDateRangeEnd.minDate = start.toDate();
      $scope.officeClosing.end = start.toDate();
    }
  }, true);

  $scope.$watch('currentYear', function (newYear, oldYear) {
    if (oldYear !== newYear) {
      LoadingIndicatorService.show();
      CountryConfigResource
        .get({countryCode: $scope.office.address.countryCode, years: newYear})
        .$promise
        .then((response) => {
          angular.forEach(response.countryConfig.holidays, function (days, year) {
            angular.forEach(response.countryConfig.holidays[year], function (holiday, date) {
              holiday.date = new Date(holiday.date);
              response.countryConfig.holidays[year][date].key = holiday.date.getDayKey();
            });
          });
          LoadingIndicatorService.hide();
          $scope.countryConfig = response.countryConfig;
        })
        .catch(() => {
          LoadingIndicatorService.hide();
        })
    }
  })

  $scope.dtpOptionsDateRangeStart = angular.copy(DatepickerSettings.default);
  $scope.dtpOptionsDateRangeEnd = angular.copy(DatepickerSettings.default);

  /**
   * set checkbox states
   */
  angular.forEach($scope.office.openingHours.special, function (value, key) {
    $scope.holidays[key] = true;
  });


  var initHolidays = function () {
    angular.forEach($scope.years, function (year) {
      $scope.mergeHolidays(year);
    });
  };


  /**
   * active are deactive holiday opening hours overwrite
   * @param holidayKey
   */
  $scope.changeState = function (holidayKey) {
    if ($scope.holidays[holidayKey]) {
      $scope.office.openingHours.special[holidayKey] = {
        open: true,
        times: [{
          start: 540,
          end: 720
        }]
      };
    } else {
      delete $scope.office.openingHours.special[holidayKey];
    }
  };


  /**
   * add a second column to enable two time slots per day ...
   * @param holidayKey
   */
  $scope.addSecondOpeningTime = function (holidayKey) {
    if ($scope.office.openingHours.special[holidayKey].times.length === 1) {
      // get closing hour of first opening time
      var firstClosingTime = $scope.office.openingHours.special[holidayKey].times[0].end;

      $scope.office.openingHours.special[holidayKey].times.push({
        start: ((firstClosingTime + 60) > 1440) ? 1440 : firstClosingTime + 60,
        end: ((firstClosingTime + 240) > 1440) ? 1440 : firstClosingTime + 240
      });
    }
  };


  /**
   * ... end make it removeable
   * @param holidayKey
   */
  $scope.removeSecondOpeningTime = function (holidayKey) {
    if ($scope.office.openingHours.special[holidayKey].times.length === 2) {
      $scope.office.openingHours.special[holidayKey].times.splice(1, 1);
    }
  };


  /**
   * generate holidaykey
   * @param date
   * @returns {*}
   */
  $scope.getHolidayKey = function (date) {
    date = new Date(date);
    return date.getDayKey();
  };


  $scope.mergeHolidays = function (year) {
    angular.forEach($scope.office.openingHours.special, function (holiday, key) {
      if (holiday.custom && key.substring(0, 4) === year) {
        var date = new Date(key);

        $scope.countryConfig.holidays[year][key.substring(5, 10)] = {
          date: date,
          custom: holiday.custom,
          key: date.getDayKey(),
          name: holiday.name,
          public: false
        };
      }
    });
  };

  $scope.addNewHoliday = function () {
    if ($scope.newHoliday.name && angular.isDate($scope.newHoliday.date)) {
      var holidayKey = $scope.newHoliday.date.getDayKey();

      $scope.office.openingHours.special[holidayKey] = {
        name: $scope.newHoliday.name,
        open: true,
        custom: true,
        date: $scope.newHoliday.date,
        times: []
      };

      $scope.save();

      $scope.newHoliday = {
        name: '',
        date: new Date($scope.currentYear, 0, 1)
      };

      $scope.mergeHolidays($scope.currentYear);
    }
  };

  $scope.delete = function (dayKey) {
    var customHoliday = $scope.office.openingHours.special[dayKey];
    var shortDateKey = dayKey.substring(5);

    if (customHoliday && customHoliday.custom) {
      delete $scope.office.openingHours.special[dayKey];
      delete $scope.countryConfig.holidays[$scope.currentYear][shortDateKey];

      $scope.save(function () {
        $scope.mergeHolidays($scope.currentYear);
        Notification.addSuccessMessage(gettextCatalog.getString('Holiday removed'));
      });
    }
  };


  /**
   * save to rest webservice
   */
  $scope.save = function (cb) {
    angular.forEach($scope.holidays, function (day, key) {
      if (!day) {
        delete $scope.office.openingHours.special[key];
      }
    });

    OfficeDA
      .update($scope.office)
      .then(() => {
        Notification.addSuccessMessage(gettextCatalog.getString('Holiday saved'));
      })
      .catch((error) => {
        Notification.addErrorMessage({text: error});
      });
  };

  $scope.addClosingTime = function () {
    var alreadyExists = false;

    $scope.office.openingHours.businessHolidays = $scope.office.openingHours.businessHolidays || [];

    // check if already exists
    angular.forEach($scope.office.openingHours.businessHolidays, function (item) {
      if (moment(item.start).isSame($scope.officeClosing.start, 'day') &&
        moment(item.end).isSame($scope.officeClosing.end, 'day')) {
        alreadyExists = true;
      }
    });

    if (alreadyExists) {
      console.log('cannot add')
    } else {
      $scope.office.openingHours.businessHolidays.push({
        start: $scope.officeClosing.start,
        end: $scope.officeClosing.end
      });
    }
  };

  $scope.removeBusinessHoliday = function (index) {
    $scope.office.openingHours.businessHolidays.splice(index, 1);
  };

  $scope.yearChanged = function () {
    $scope.newHoliday.date = new Date($scope.currentYear, 0, 1);
  };

  initHolidays();

});

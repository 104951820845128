'use strict';

module.exports = function ($timeout,
                           PixelTimeService,
                           AppointmentDragAndResizeState,
                           MobileDetectService,
                           AppointmentDA) {

  return {
    restrict: 'E',
    require: ['^appointments', 'appointment'],
    templateUrl: 'https://cdn.termin2go.com/app/app/modules/calendar/AppointmentView.html?v=3.82.0',
    replace: true,
    controller: 'AppointmentCtrl as AppointmentCtrl',
    link: function ($scope, $element, $attributes, controllers) {
      var appointmentsCtrl = controllers[0];
      var appointmentCtrl = controllers[1];
      var preparationElement = $element.find('.appointment__pause--top');
      var followUpElement = $element.find('.appointment__pause--bottom');
      var freeTimeElement;

      if ($scope.appointment.freeTimeStart) {
        freeTimeElement = $element.find('.appointment__pause--middle');
      }

      appointmentsCtrl.add($scope);

      /**
       * set vertical position of appointment
       * @param {Number} width
       * @param {Number} left
       */
      $scope.setVerticalPos = function (width, left) {
        $element.css('left', left + '%');
        $element.css('width', width + '%');
      };


      /**
       * (re)draw appointment
       */
      $scope.draw = function () {
        // set horizontal pos
        var appointmentPosition = appointmentCtrl.calculateVerticalPosition('start', 'end');
        var preparationPosition = appointmentCtrl.calculateVerticalPosition('preparationStart', 'start');
        var followUpPosition = appointmentCtrl.calculateVerticalPosition('end', 'followUpEnd');
        var freeTimePosition;

        if ($scope.appointment.freeTimeStart) {
          freeTimePosition = appointmentCtrl.calculateVerticalPosition('freeTimeStart', 'freeTimeEnd');
          freeTimeElement.css({
            top: freeTimePosition.top - appointmentPosition.top + 'px',
            height: freeTimePosition.height + 'px'
          });
        }

        $element.css({
          top: appointmentPosition.top + 'px',
          height: appointmentPosition.height + 'px'
        });

        if (!preparationPosition.hideStart) {
          preparationElement.css({
            top: -preparationPosition.height + 'px',
            height: preparationPosition.height + 'px'
          });
        } else {
          preparationElement.remove();
        }

        if (!followUpPosition.hideEnd) {
          followUpElement.css({
            bottom: -followUpPosition.height + 'px',
            height: followUpPosition.height + 'px'
          });
        } else {
          followUpElement.remove();
        }

        if ($scope.$last) {
          $timeout(function () {
            appointmentsCtrl.calcGroups();
          });
        }
      };

      $scope.$on('$destroy', function () {
        appointmentsCtrl.remove($scope);
      });

      $scope.$on('angular-resizable.resizeStart', function (event, data) {
        AppointmentDragAndResizeState.activate();
      });

      $scope.$on('angular-resizable.resizeEnd', function (event, data) {
        var newDuration = PixelTimeService.pixelToTime(data.height, false);
        var newDurationMinutes = newDuration.hours * 60 + newDuration.roundedMinutes;

        $scope.appointment.end = moment($scope.appointment.start).add(newDurationMinutes, 'minutes').toDate();

        AppointmentDA.update($scope.appointment);
        AppointmentDragAndResizeState.deactivate();
      });

      if (!MobileDetectService.isMobile) {
        $element.draggable({
          snap: '.calendar__appointment-column',
          snapMode: 'inner',
          snapTolerance: 50,
          distance: 20,
          opacity: 0.7,
          revert: 'invalid',
          start: function () {
            // sendNotifications
            AppointmentDragAndResizeState.activate();
          },
          stop: function () {
            AppointmentDragAndResizeState.deactivate();
          }
        });
      }

      // initial draw of appointment
      $scope.draw();
    }
  };
};

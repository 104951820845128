// load lib like Google Analytics ...
require('mandant/3rdPartyLibs').load();

var dependencies = [
  'gettext',
  'ngAnimate',
  'ngRoute',
  'ngCookies',
  'ngSanitize',
  'ngMessages',
  'ngclipboard',
  'credit-cards',
  'mm.iban',
  require('./common/commonModule').name,
  require('./modules/account/accountModule').name,
  require('./modules/accounting/accountingModule').name,
  require('./modules/branches/branchesModule').name,
  require('./modules/calendar/calendarModule').name,
  require('./modules/course/courseModule').name,
  require('./modules/customer/customerModule').name,
  require('./modules/customFields/customFieldsModule').name,
  require('./modules/export/exportModule').name,
  require('./modules/googleSync/googleSyncModule').name,
  require('./modules/holidays/holidaysModule').name,
  require('./modules/mailTemplate/mailTemplateModule').name,
  require('./modules/message/messageModule').name,
  require('./modules/marketing/marketingModule').name,
  require('./modules/openingHours/openingHoursModule').name,
  require('./modules/pageWidget/pageWidgetModule').name,
  require('./modules/privacy/privacyModule').name,
  require('./modules/profile/profileModule').name,
  require('./modules/resources/resourcesModule').name,
  require('./modules/service/serviceModule').name,
  require('./modules/settings/settingsModule').name,
  require('./modules/shiftPlanning/shiftPlanningModule').name,
  require('./modules/statistic/statisticModule').name,
  require('./modules/subscription/subscriptionModule').name,
  require('./modules/testManagement/testManagementModule').name,
  require('./modules/trash/trashModule').name,
  require('./modules/user/userModule').name,
  require('./modules/pin/pinModule').name,
  require('./modules/log/logModule').name,
  require('mandant/modules').name,
  'ui.bootstrap',
  'ui.router',
  'ui.select',
  'ui.grid',
  'ui.grid.autoResize',
  'ui.grid.selection'
];

var t2gApp = angular.module('t2g', dependencies);

t2gApp.config(function ($httpProvider,
                        $stateProvider,
                        $urlRouterProvider,
                        $urlServiceProvider,
                        $sceDelegateProvider,
                        uibDatepickerPopupConfig,
                        uiSelectConfig) {

  uiSelectConfig.resetSearchInput = true;
  uiSelectConfig.appendToBody = true;

  $urlServiceProvider.config.strictMode(false);

  $urlRouterProvider.otherwise('/private/main');

  $sceDelegateProvider.resourceUrlWhitelist([
    'self',
    'https://cdn-dev.termin2go.com/**',
    'https://cdn.termin2go.com/**'
  ]);

  $stateProvider
    .state('private', {
      url: '/private',
      abstract: true,
      template: '<div class="inner-view-container" ui-view></div>',
      resolve: {
        session: function (UserDA, languageSwitch, gettextCatalog) {
          return UserDA
            .ping()
            .then((user) => {
              languageSwitch.init(user.language).then(function () {
                uibDatepickerPopupConfig.closeText = gettextCatalog.getString('Close');
                uibDatepickerPopupConfig.currentText = gettextCatalog.getString('Today');
              });

              return user;
            });
        },
        subscription: function (SubscriptionDA, SubscriptionState) {
          return SubscriptionDA
            .get()
            .then(function (response) {
              if (response.trialDaysLeft === 0 || response.package === 'basic') {
                SubscriptionState.setShouldSubscribe(true);
              }

              return response;
            });
        },
        bookings: (BookingDA) => {
          return BookingDA
              .get()
              .then((bookingResponse) => bookingResponse.bookings);
        }
      }
    })
    .state('private.main', {
      url: '/main',
      controller: 'MainCtrl',
      templateUrl: 'https://cdn.termin2go.com/app/app/PrivateView.html?v=3.82.0',
      resolve: {
        officeLabelResolve: function (OfficeDA, IndustrySpecific) {
          let officeResolve;
          return OfficeDA
            .get()
            .then((response) => {
              officeResolve = response;

              return IndustrySpecific.getLabels();
            })
            .then((industrySpecificResolve) => {
              return {
                industrySpecificResolve,
                officeResolve
              }
            })
        },
        industryResolve: function (IndustryDA) {
          return IndustryDA.list();
        },
        countryResolve: function (CountryConfig) {
          return CountryConfig.getCountries();
        },
        resourceResolve: function (ResourceDA) {
          return ResourceDA
            .list()
            .then(function (response) {
              return response.resources;
            });
        },
        customers: function (CustomerDA) {
          return CustomerDA.list();
        }
      }
    });

  // disable json cache in IE
  if (!$httpProvider.defaults.headers.get) {
    $httpProvider.defaults.headers.get = {};
  }

  $httpProvider.interceptors.push(function ($q, $window, settings) {
    return {
      request: function (config) {
        return config || $q.when(config);
      },
      requestError: function (rejection) {
        return $q.reject(rejection);
      },
      response: function (response) {
        return response || $q.when(response);
      },
      responseError: function (rejection) {
        if (rejection.status === 401) {
          $window.location = settings.login;
        }

        return $q.reject(rejection);
      }
    };
  });
});

t2gApp.run(function ($anchorScroll,
                     $location,
                     $rootScope,
                     $trace,
                     $state,
                     $window,
                     $transitions,
                     $timeout,
                     gettextCatalog,
                     LocalStorage,
                     settings,
                     uibDatepickerPopupConfig,
                     MobileDetectService,
                     SubscriptionState) {

  $rootScope.mandant = settings.mandant;
  $rootScope.isMobile = MobileDetectService.isMobile;
  $rootScope.isSM = MobileDetectService.isSM;

  $transitions.onStart({}, function () {
    $rootScope.isViewLoading = true;
    $window.ga && $window.ga('send', 'pageview', $location.path());
    $anchorScroll();
    NProgress.start();
  });

  $transitions.onSuccess({}, function (transition) {
    var toState = transition.to().name;
    var fromState = transition.from().name;

    $rootScope.isViewLoading = false;
    NProgress.done();

    if (fromState === 'private.main.customerProfile') {
      LocalStorage.remove('openAppointment');
      LocalStorage.remove('openCash');
    }

    if (toState === 'private.main') {
      $state.go('private.main.calendar');
    }

    $rootScope.currentState = toState.replace('private.main.', '').replace(/\./g, '-');

    if (['subscription', 'billingDetails', 'paymentDetails', 'finalize', 'private-main'].indexOf($rootScope.currentState) === -1 &&
      SubscriptionState.getShouldSubscribe()) {
      $state.go('private.main.subscription', {openTab: 'plans'});
    }
  });

  // stop current tour on changing view
  $transitions.onFinish({}, function () {
    if (typeof introJs !== 'undefined') {
      introJs().exit();
    }
  });

  $transitions.onError({}, function (transition) {
    console.log('error', transition.error().message, transition);
  });
});

t2gApp.controller('MainCtrl', function ($location,
                                        $rootScope,
                                        $scope,
                                        $state,
                                        $timeout,
                                        $window,
                                        AppointmentDA,
                                        BookingDA,
                                        CalendarDA,
                                        countryResolve,
                                        gettextCatalog,
                                        industryResolve,
                                        IndustrySpecific,
                                        // io,
                                        Message,
                                        ModalService,
                                        Notification,
                                        OfficeDA,
                                        officeLabelResolve,
                                        resourceResolve,
                                        UserSession,
                                        bookings,
                                        uibDatepickerPopupConfig,
                                        subscription) {

  const { industrySpecificResolve, officeResolve } = officeLabelResolve;
  $scope.industries = industryResolve;
  $scope.office = officeResolve;
  $scope.countries = countryResolve;
  $scope.isViewLoading = false;
  $scope.uncheckedSteps = 4;
  $scope.currentUser = UserSession.get();
  $scope.unprocessedMessages = 0;
  $scope.bookings = bookings;
  $scope.loadingAccept = false;
  $scope.loadingDecline = false;
  $scope.showOverlay = false;
  $scope.overlayStep = 1;
  $scope.showValidation = false;
  $scope.overlayLoading = false;
  $scope.showChoosePricemodel = false;
  $scope.showBooking = true;
  $scope.subscription = subscription;
  $scope.labels = {
    customer: gettextCatalog.getString(industrySpecificResolve.customer.toLowerCase()),
    customers: gettextCatalog.getString(industrySpecificResolve.customers.toLowerCase())
  };
  $scope.selectedIndustry = null;

  // load user data from session
  if ($scope.currentUser._id) {
    $scope.user = UserSession.get();
  }

  // show booking window if auto accept is deactivated
  // TODO mobile only
  if (angular.isDefined($scope.office.settings.acceptBooking) && $scope.office.settings.acceptBooking === 0) {
    $scope.showBooking = false;
  }

  // set email and name for chat
  if (window.zE) {
    zE(function () {
      $zopim(function () {
        $zopim.livechat.setEmail($scope.currentUser.email);
        $zopim.livechat.setName($scope.currentUser.firstname + ' ' + $scope.currentUser.lastname);
      });
    });
  }

  if (window.__insp && window.location.href.indexOf('app.termin2go.com') > -1) {
    window.__insp.push(['identify', $scope.currentUser.email]);

    if ($scope.office && subscription.trialDaysLeft > 0) {
      window.__insp.push(['tagSession', { email: $scope.currentUser.email, office: $scope.office.name, package: subscription.package }]);
    }
  }

  // load industry specific labels
  var loadLabels = function () {
    IndustrySpecific
      .getLabels()
      .then(function (labels) {
        $scope.labels.customer = gettextCatalog.getString(labels.customer);
        $scope.labels.customers = gettextCatalog.getString(labels.customers);
      });
  };

  // check if name, industry and country code are filled. If not, show overlay
  if (!$scope.office.name ||
    !$scope.office.profile.industries ||
    !$scope.office.profile.industries.length ||
    !$scope.office.address.countryCode) {
    $scope.showOverlay = true;

    if ($scope.office.profile.industries && $scope.office.profile.industries.length) {
      $scope.selectedIndustry = $scope.office.profile.industries[0];
    }
  } else {
    // TODO update remove
    if ($scope.office.profile.industries && $scope.office.profile.industries.length) {
      $scope.selectedIndustry = $scope.office.profile.industries[0];
    }

    $scope.showOverlay = false;
  }


  // save overlay form (office name, industry and country ...) to database
  $scope.saveOverlayForm = async function () {
    $scope.showValidation = true;
    $scope.overlayLoading = true;

    if ($scope.OverlayAddressForm.$valid) {
      $scope.office.profile.industries = [$scope.selectedIndustry];
      $scope.office.settings = $scope.office.settings || {};

      if ($scope.office.address.countryCode === 'CH') {
        $scope.office.settings.currency = 'CHF';
      } else {
        $scope.office.settings.currency = 'EUR';
      }

      try {
        await OfficeDA.update($scope.office);
        loadLabels();
        $scope.overlayLoading = false;
        $scope.showOverlay = false;
        $state.go('private.main.calendar');
        // $state.go('private.main.resources');

        // set introJs options and start tour
        if (typeof introJs !== 'undefined') {
          introJs().setOptions({
            nextLabel: gettextCatalog.getString('next'),
            prevLabel: gettextCatalog.getString('back'),
            skipLabel: gettextCatalog.getString('skip'),
            doneLabel: gettextCatalog.getString('done'),
            exitOnOverlayClick: false,
            exitOnEsc: false,
          });
        }

        Notification.addSuccessMessage(gettextCatalog.getString('Saved successfully'));
      } catch (error) {
        Notification.addErrorMessage({text: error});
      }
    } else {
      $scope.overlayLoading = false;
    }
  }

  /**
   * load industry specific labels
   */
  var loadLabels = function () {
    IndustrySpecific
      .getLabels()
      .then(function (labels) {
        $scope.labels.customer = gettextCatalog.getString(labels.customer);
        $scope.labels.customers = gettextCatalog.getString(labels.customers);
      });
  };

  /**
   * load bookings
   */

  /**
   * check user role. needed for navigation
   *
   * @param {string} minimumRole
   * @returns {boolean}
   */
  $scope.checkRole = function (minimumRole) {
    return UserSession.checkRole(minimumRole);
  };

  /**
   * disable double click
   * @returns {boolean}
   */
  $scope.disableClick = function () {
    return false;
  };

  /**
   * accept booking
   */
  $scope.accept = function () {
    var lastBooking = $scope.bookings[0];
    $scope.loadingAccept = true;

    BookingDA
        .accept(lastBooking)
        .then(function () {
          $scope.bookings.splice(0, 1);
          $scope.loadingAccept = false;
          Notification.addSuccessMessage(gettextCatalog.getString('Booking accepted'));
        })
        .catch(function () {
          $scope.loadingAccept = false;
          Notification.addErrorMessage(gettextCatalog.getString('Cant accept booking'));
          loadBookings();
        });
  };

  /**
   * decline the booking
   */
  $scope.decline = function () {
    var lastBooking = $scope.bookings[0];
    $scope.loadingDecline = true;

    BookingDA
        .decline(lastBooking)
        .then(function (response) {
              $scope.bookings.splice(0, 1);
              $scope.$broadcast('Booking:decline', response);
              Notification.addSuccessMessage(gettextCatalog.getString('Booking declined'));
              $scope.loadingDecline = false;
            },
            function () {
              $scope.loadingDecline = false;
              Notification.addErrorMessage(gettextCatalog.getString('Cant accept booking'));
            }
        );
  };

  $scope.overlayForward = function (formToValidate) {
    if (formToValidate && !$scope[formToValidate].$valid) {
      $scope.showValidation = true;
      return;
    }

    $scope.showValidation = false;
    $scope.overlayStep++;
  };

  $scope.overlayBack = function () {
    $scope.overlayStep--;
  };
  var loadBookings = function () {
    BookingDA
        .get()
        .then(function (bookingResponse) {
          $scope.bookings = bookingResponse.bookings;
        });
  };

  /**
   * reload bookings on update
   */
  $scope.$on('Booking:delete', function () {
    loadBookings();
  });


  /**
   * remove event listeners
   */
  $scope.$on('$destroy', function () {
    if (typeof io !== 'undefined' && io.off) {
      io.off();
    }
  });

  // if ($scope.subscription &&
  //   $scope.subscription.billingInformation &&
  //   $scope.subscription.billingInformation.country !== 'DE' &&
  //   !$scope.subscription.billingInformation.vatID &&
  //   $scope.currentUser && $scope.currentUser.role === 'admin') {
  //   ModalService.openVatIDModal();
  // }
});

Date.prototype.getDayKey = function () {
  var year = this.getFullYear();
  var month = this.getMonth() + 1;
  var day = this.getDate();

  return year + '-' + ('0' + month).slice(-2) + '-' + ('0' + day).slice(-2);
};


Array.prototype.move = function (old_index, new_index) {
  if (new_index >= this.length) {
    var k = new_index - this.length;
    while ((k--) + 1) {
      this.push(undefined);
    }
  }
  this.splice(new_index, 0, this.splice(old_index, 1)[0]);
  return this;
};

if (!Element.prototype.matches) {
  Element.prototype.matches =
    Element.prototype.matchesSelector ||
    Element.prototype.mozMatchesSelector ||
    Element.prototype.msMatchesSelector ||
    Element.prototype.oMatchesSelector ||
    Element.prototype.webkitMatchesSelector ||
    function (s) {
      var matches = (this.document || this.ownerDocument).querySelectorAll(s),
        i = matches.length;
      while (--i >= 0 && matches.item(i) !== this) {
      }
      return i > -1;
    };
}

'use strict';

var modalServiceModule = angular.module('t2g.common.services.modalServiceNew', []);

module.exports = modalServiceModule;

var TEMPLATE_BASE = 'https://cdn.termin2go.com/app/app/modules/';

var APPOINTMENT_TYPES = {
  customer: {
    template: 'calendar/CustomerAppointmentLayoverView.html?v=3.82.0',
    controller: 'CustomerAppointmentModalCtrl as AppointmentModalCtrl'
  },
  course: {
    template: 'calendar/CourseAppointmentLayoverView.html?v=3.82.0',
    controller: 'CourseAppointmentModalCtrl as AppointmentModalCtrl'
  },
  private: {
    template: 'calendar/PrivateAppointmentLayoverView.html?v=3.82.0',
    controller: 'PrivateAppointmentModalCtrl as AppointmentModalCtrl'
  }
};

modalServiceModule.factory('ModalService', function (ModalFactory, OfficeDA, UserSession, $q) {

  return {
    openVatIDModal,
    openScanQRModal,
    openTestModal,
    openCancelAccountModal,
    openAppointmentModal,
    openServiceModal,
    openServiceLocationModal,
    openCashModal,
    openCustomFieldsModal,
    openCashEntryModal,
    openCashEntryCancelModal,
    openDayModal,
    openUserModal,
    openDeleteSeriesAppointmentModal,
    openPINModal
  };

  function resolveOffice(OfficeDA) {
    return OfficeDA.get();
  }

  function resolveUser(UserSession) {
    return UserSession.get();
  }

  function resolveCustomers(CustomerDA) {
    return CustomerDA.list();
  }

  function resolveCustomLabels(IndustrySpecific) {
    return IndustrySpecific.getLabels();
  }

  function taxes(CountryConfig) {
    return CountryConfig
      .get()
      .then(function (config) {
        return config.taxes || [{ value: 19 }, { value: 16 }, { value: 7 }, { value: 5 }, { value: 0 }];
      });
  }

  function resolveServices(OfficeDA) {
    var services = [];

    return OfficeDA
      .get()
      .then(function (office) {
        office.serviceGroups.forEach((group) => {
          group.services.forEach((service) => {
            service.group = group.name;
            service.shorthand = service.shorthand || '';
          });

          services = services.concat(group.services);
        });

        return services;
      });
  }

  function openTestModal(appointmentId, closeCallback) {
    var modalInstance = ModalFactory.open({
      template: TEMPLATE_BASE + 'testManagement/TestLayoverView.html?v=3.82.0',
      controller: 'TestLayoverCtrl as TestLayoverCtrl',
      resolve: {
        test(TestDA) {
          return TestDA.getTest(appointmentId);
        },
        office: resolveOffice,
        customLabels: resolveCustomLabels,
        employees: function (ResourceDA) {
          return ResourceDA
            .list('employee')
            .then(response => response.resources )
        },
      }
    });

    modalInstance
      .closed
      .then(closeCallback);
  }

  function openVatIDModal(closeCallback) {
    var modalInstance = ModalFactory.open({
      template: TEMPLATE_BASE + 'subscription/VatIDModalModal.html?v=3.82.0',
      controller: 'VatIDModalCtrl as VatIDModalCtrl'
    });


    modalInstance
      .closed
      .then(closeCallback);
  }

  function openScanQRModal(closeCallback) {
    var modalInstance = ModalFactory.open({
      template: TEMPLATE_BASE + 'testManagement/ScanQRModal.html?v=3.82.0',
      controller: 'ScanQRModalCtrl as ScanQRModalCtrl',
      resolve: {
        office: resolveOffice,
      }
    });


    modalInstance
      .closed
      .then(closeCallback);
  }

  function openCancelAccountModal(closeCallback) {
    var modalInstance = ModalFactory.open({
      template: TEMPLATE_BASE + 'subscription/CancelAccountModal.html?v=3.82.0',
      controller: 'CancelAccountModalCtrl as CancelAccountModalCtrl',
      resolve: {}
    });


    modalInstance
      .closed
      .then(closeCallback);
  }

  function openUserModal(userId, closeCallback) {
    var modalInstance = ModalFactory.open({
      template: TEMPLATE_BASE + 'user/UserModal.html?v=3.82.0',
      controller: 'UserModalCtrl as UserModalCtrl',
      resolve: {
        user(UserDA) {
          if (userId) {
            return UserDA.get(userId);
          }

          return {
            role: 'admin',
            rights: {
              appointments: {
                edit: 'all'
              },
              workingHours: {
                edit: 'all'
              }
            }
          };
        },
        userSession(UserSession) {
          return UserSession.get();
        },
        employees: function (ResourceDA) {
          return ResourceDA
            .getAll()
            .then((response) => {
              return response.resources.map((employee) => {
                employee.label = `${employee.lastname}, ${employee.firstname}`;

                if(employee.branch) {
                  employee.label += ` (${employee.branch})`;
                }

                return employee;
              });
            });
        },
      }
    });

    modalInstance
      .closed
      .then(closeCallback);
  }

  function openCashEntryModal(type, date, closeCallback) {
    var modalInstance = ModalFactory.open({
      template: TEMPLATE_BASE + 'accounting/CashEntryModal.html?v=3.82.0',
      controller: 'CashEntryModalCtrl as CashEntryModalCtrl',
      resolve: {
        type: function () {
          return type;
        },
        date: function () {
          return date;
        },
        office: resolveOffice
      }
    });

    modalInstance
      .closed
      .then(closeCallback);
  }

  function openDayModal(closeCallback) {
    var modalInstance = ModalFactory.open({
      template: TEMPLATE_BASE + 'accounting/OpenDayModal.html?v=3.82.0',
      controller: 'OpenDayModalCtrl as OpenDayModalCtrl',
      resolve: {
        office: resolveOffice
      }
    });

    modalInstance
      .closed
      .then(closeCallback);
  }

  function openCustomFieldsModal(customField, closeCallback) {
    var modalInstance = ModalFactory.open({
      template: TEMPLATE_BASE + 'customFields/CustomFieldsModal.html?v=3.82.0',
      controller: 'CustomFieldsModalCtrl as CustomFieldsModalCtrl',
      resolve: {
        customField: function () {
          return customField;
        }
      }
    });

    modalInstance
      .closed
      .then(closeCallback);
  }

  function openCashEntryCancelModal(cashBookEntry, closeCallback) {
    var modalInstance = ModalFactory.open({
      template: TEMPLATE_BASE + 'accounting/CashEntryCancelModal.html?v=3.82.0',
      controller: 'CashEntryCancelModalCtrl as CashEntryCancelModalCtrl',
      resolve: {
        cashBookEntry: function () {
          return cashBookEntry;
        },
        office: resolveOffice
      }
    });

    modalInstance
      .closed
      .then(closeCallback);
  }

  function openCashModal(openCash, closeCallback) {
    var modalInstance = ModalFactory.open({
      template: TEMPLATE_BASE + 'accounting/CashModal.html?v=3.82.0',
      controller: 'CashModalCtrl as CashModalCtrl',
      resolve: {
        office: resolveOffice,
        customers: resolveCustomers,
        customLabels: resolveCustomLabels,
        services: resolveServices,
        cash: function () {
          return openCash || {
            positions: [],
            total: 0,
            cashed: new Date()
          };
        },
        taxes
      }
    });

    modalInstance
      .closed
      .then(closeCallback);
  }

  function openPINModal(office, user) {
    return $q.all([
      OfficeDA.get(),
      UserSession.get()
    ]).then((responses) => {
      const [office, user] = responses;
      const pinSettings = _.get(office, 'settings.employeePIN');

      // TODO check cookie
      if (pinSettings && pinSettings.enabled && !pinSettings.disabledPins[user._id]) {
        var modalInstance = ModalFactory.open({
          template: TEMPLATE_BASE + 'pin/PinModal.html?v=3.82.0',
          controller: 'PinModalCtrl as PinModalCtrl',
          forceFront: true,
          resolve: {
            office: resolveOffice,
            user: resolveUser
          }
        });

        return modalInstance.closed;
      } else {
        return $q.resolve();
      }
    })
  }

  function openDeleteSeriesAppointmentModal(appointment, closeCallback) {
    var modalInstance = ModalFactory.open({
      template: TEMPLATE_BASE + 'calendar/DeleteSeriesModal.html?v=3.82.0',
      controller: 'DeleteSeriesModalCtrl as DeleteSeriesModalCtrl',
      resolve: {
        appointment() {
          return appointment;
        }
      }
    });

    modalInstance
      .closed
      .then(closeCallback);
  }

  function openServiceLocationModal(closeCallback) {
    var modalInstance = ModalFactory.open({
      template: TEMPLATE_BASE + 'service/modal/ServiceLocationModal.html?v=3.82.0',
      controller: 'ServiceLocationModalCtrl as ServiceLocationModalCtrl',
      resolve: {
        office: resolveOffice,
      }
    });

    modalInstance
      .closed
      .then(closeCallback);
  }

  function openServiceModal(service, closeCallback) {
    var modalInstance = ModalFactory.open({
      template: TEMPLATE_BASE + 'service/modal/ServiceModal.html?v=3.82.0',
      controller: 'ServiceModalCtrl as ServiceModalCtrl',
      resolve: {
        taxes,
        service: function () {
          return service;
        },
        subscription: function (SubscriptionDA) {
          return SubscriptionDA.get();
        },
        office: resolveOffice,
        resources: (ResourceDA) => {
          return ResourceDA
            .list()
            .then(function (response) {
              return response.resources;
            });
        }
      }
    });

    modalInstance
      .closed
      .then(closeCallback);
  }

  function openAppointmentModal(appointment, initTab, closeCallback) {
    var type = _.get(appointment, 'type', 'customer');
    var appointmentTypeSettings = APPOINTMENT_TYPES[type];

    var modalInstance = ModalFactory.open({
      template: TEMPLATE_BASE + appointmentTypeSettings.template,
      controller: appointmentTypeSettings.controller,
      resolve: {
        customLabels: resolveCustomLabels,
        customers: resolveCustomers,
        services: resolveServices,
        taxes,
        initTab() {
          return initTab || 'appointment';
        },
        appointment: ['AppointmentDA', function (AppointmentDA) {
          if (appointment && appointment._id) {
            return AppointmentDA.get(appointment._id, type, appointment.migrated);
          } else {
            var mergedAppointment = {};
            var start = new Date();
            start.setMinutes(0);
            start.setSeconds(0);
            var defaultAppointment = {
              type: 'customer',
              start: start,
              end: moment(start).add(1, 'hour').toDate(),
              migrated: false,
              recurring: {
                enabled: false
              },
              reminder: {
                email: {
                  enabled: false
                },
                sms: {
                  enabled: false
                }
              }
            };

            _.merge(mergedAppointment, defaultAppointment, appointment);

            if (mergedAppointment.type === 'customer') {
              mergedAppointment.services = mergedAppointment.services || [];
              mergedAppointment.price = mergedAppointment.price || 0;
            } else if (mergedAppointment.type === 'course') {
              mergedAppointment.participants = mergedAppointment.participants || [];
              mergedAppointment.membershipSettings = mergedAppointment.membershipSettings || [];
            }

            mergedAppointment.start = new Date(mergedAppointment.start);
            mergedAppointment.end = new Date(mergedAppointment.end);

            return mergedAppointment;
          }
        }],
        resources: function (ResourceDA) {
          var groupedResources = {
            employees: [],
            rooms: []
          };

          return ResourceDA
            .list()
            .then(function (response) {
              _.forEach(response.resources, function (resource) {
                if (resource.type === 'room') {
                  groupedResources.rooms.push(resource);
                } else {
                  groupedResources.employees.push(resource);
                }
              });

              return groupedResources;
            });
        },
        office: resolveOffice,
        subscription: function (SubscriptionDA) {
          return SubscriptionDA.get();
        },
      }
    });

    modalInstance
      .closed
      .then(closeCallback);
  }

});

'use strict';

var AppointmentModalBaseCtrl = require('./AppointmentModalBaseCtrl');

function getServiceFromOffice(office, serviceId) {
  for (var i = 0, serviceGroupsLen = office.serviceGroups.length; i < serviceGroupsLen; i++) {
    var serviceGroup = office.serviceGroups[i];

    for (var j = 0, servicesLen = serviceGroup.services.length; j < servicesLen; j++) {
      var service = serviceGroup.services[j];

      if (service._id === serviceId) {
        return service;
      }
    }
  }
}

var CustomerAppointmentModalCtrl = function ($controller,
                                             $injector,
                                             $rootScope,
                                             $scope,
                                             appointment,
                                             customers,
                                             customLabels,
                                             gettextCatalog,
                                             ModalService,
                                             AccountingDA,
                                             DocumentDA,
                                             taxes,
                                             modalInstance,
                                             resources,
                                             services,
                                             subscription,
                                             Notification,
                                             initTab,
                                             office) {

  AppointmentModalBaseCtrl.call(this, modalInstance, $injector);

  var initialEmployeeId = _.get(appointment, 'object._id');
  var initialRoomId = _.get(appointment, 'room');
  var covidTests = _.get(office, 'settings.covidTest.tests');
  this.customerLabel = gettextCatalog.getString(customLabels.customer.toLowerCase());
  this.gettextCatalog = gettextCatalog;
  this.customers = customers;
  this.services = services;
  this.initTab = initTab;
  this.appointment = appointment;
  this.resources = resources;
  this.rootScope = $rootScope;
  this.taxes = taxes;
  this._ModalService = ModalService;
  this._AccountingDA = AccountingDA;
  this._DocumentDA = DocumentDA;
  this._Notification = Notification;
  this.office = office;
  this.subscription = subscription;
  this.smsReminderEnabled = _.get(this.office, 'settings.reminder.sms.enabled', false);
  this.emailReminderEnabled = _.get(this.office, 'settings.reminder.email.enabled', false);
  this.showReminderTab = this.smsReminderEnabled || this.emailReminderEnabled;
  this.currentService = null;
  this.cashBookEntry = null;
  this.cashBookEntryLoaded = false;
  this.sendNotificationEnabled = _.get(office, 'settings.sendAppointmentChangeNotificationMails', false);
  this.isCheckoutLoading = false;
  this.helloCashCheckoutUrl = '';
  this.isPackageIsSchnelltest = ['schnelltest', 'schnelltest2', 'schnelltest_archiv'].includes(subscription.package);
  this.showLog = ['plus', 'enterprise'].includes(subscription.package);
  this.isCovidTestEnabled = _.get(this.office, 'settings.covidTest.enabled', false) && _.get(this.office, 'settings.covidTest.provider') === 'termin2go' && this.isPackageIsSchnelltest;
  this.isCWAEnabled = _.get(this.office, 'settings.covidTest.cwa.enabled', false);
  this.isDocumentLoading = false;
  this.isDocumentError = false;
  this.documentInFuture = false;
  this.missingDocumentParams = [];
  var customerFullName = _.get(this.appointment, 'customer.fullname');
  const slotLength = _.get(this.office, 'settings.slotLength', 15);
  this.slotLength = slotLength < 5 ? 1 : 5;
  this.sendDocumentToCustomer = true;
  this.sendDocumentToHealthDepartment = false;
  this.selectedTest = covidTests && covidTests.length ? covidTests[0] : null;
  this.isInFuture = moment(appointment.start).isAfter(moment(), 'day');
  this.testType = '';
  this.testReasons = [
    // {
    //   id: 9,
    //   label: 'Angehörige von Infizierten (kostenfrei)'
    // },
    {
      id: 10,
      label: 'Pflegende Angehörige sowie Menschen mit Behinderungen und deren Betreuungskräfte (kostenfrei)'
    },
    {
      id: 11,
      label: 'Besucher von Kliniken und Pflegeheimen (kostenfrei)'
    },
    // {
    //   id: 12,
    //   label: 'Menschen die sich nicht impfen lassen können (kostenfrei)'
    // },
    // {
    //   id: 13,
    //   label: 'Frauen zu Beginn der Schwangerschaft (kostenfrei)'
    // },
    // {
    //   id: 14,
    //   label: 'Kinder bis fünf Jahre (kostenfrei)'
    // },
    // {
    //   id: 15,
    //   label: 'Teilnahme an klinischen Studien zu Medikamenten gegen COVID-19 (kostenfrei)'
    // },
    // {
    //   id: 16,
    //   label: 'Freitestung (kostenfrei)'
    // },
    {
      id: 19,
      label: 'Leistungsberechtigte, die im Rahmen eines Persönlichen Budgets nach dem § 29 SGB IX Personen beschäftigen, sowie Personen, die bei Leistungsberechtigten im Rahmen eines Persönlichen Budgets beschäftigt sind (kostenfrei)'
    },
    // {
    //   id: 17,
    //   label: 'Voraufenthalt in Virusvariantengebieten'
    // // },
    // {
    //   id: 18,
    //   label: 'Bürgertestung (3,00€ Eigenbeteiligung nur für berechtigten Personengruppe)'
    // },
    {
      id: 99,
      label: `keine der genannten Gründe (${this.office.settings.covidTest.prices.rapid}€)`
    },
  ];
  this.testReasons2 = [
    {
      id: 0,
      label: 'Ich werde am Tag, an dem die Testung erfolgt eine Veranstaltung in einem Innenraum besuchen.'
    },
    {
      id: 1,
      label: 'Ich werde am Tag, an dem die Testung erfolgt Kontakt zu einer Person haben, die das 60. Lebensjahr vollendet hat.'
    },
    {
      id: 2,
      label: 'Ich werde am Tag, an dem die Testung erfolgt zu einer Person Kontakt haben, die ein hohes Risiko hat, schwer zu erkranken.'
    },
    {
      id: 3,
      label: 'Aktuell wird in meiner Corona-Warn-App der Status "erhöhtes Risiko" angezeigt.'
    },
  ];

  this.testReasonsPCR = [
    {
      id: 1,
      label: 'Kontaktperson oder Corona-Warn-App (§ 2 TestV)'
    },
    {
      id: 3,
      label: 'Ausbruchsgeschehen (§ 3 TestV)'
    },
    {
      id: 2,
      label: 'Bürgertestung (§ 4a TestV)'
    },
    {
      id: 4,
      label: 'Verhütung der Verbreitung (§ 4 Abs. 1 Nr. 1 und 2 TestV)'
    },
    {
      id: 8,
      label: 'bestätigende Testung (§ 4b  Satz 1 und 2 TestV)'
    },
    {
      id: 9,
      label: 'variantenspezifische Testung (§ 4b  Satz 3)'
    }
  ];
  this.stayReasons = [
    {
      id: 0,
      label: 'keine Angabe'
    },
    {
      id: 1,
      label: 'betreut/untergebracht'
    },
    {
      id: 2,
      label: 'Tätigkeit in Einrichtung'
    }
  ];
  this.facilityTypes = [
    {
      id: 0,
      label: 'keine Angabe'
    },
    {
      id: 1,
      label: 'Medizinische Einrichtung'
    },
    {
      id: 2,
      label: 'Gemeinschaftseinrichtung (z.B. Kitas, Schulen)'
    },
    {
      id: 3,
      label: 'Pflege- und andere Wohneinrichtung'
    },
    {
      id: 4,
      label: 'Sonstige Einrichtungen'
    }
  ];

  if (customerFullName) {
    this.title = gettextCatalog.getString('Appointment with {{customer}}', { customer: customerFullName });
  } else {
    this.title = gettextCatalog.getString('Appointment with {{customer}}', { customer: _.get(this.appointment, 'customer.firstname', '?') });
  }

  // set default resource
  if (!initialEmployeeId) {
    this.appointment.object = {
      _id: this.resources.employees[0]._id
    };
  }

  if (!appointment._id || appointment.migrated) {
    this.appointment.reminder = {
      email: {
        enabled: this.emailReminderEnabled
      },
      sms: {
        enabled: this.smsReminderEnabled
      }
    };

    // if (resources.rooms && resources.rooms.length && resources.rooms.length > 1 && !appointment.room) {
    //   this.appointment.room = resources.rooms[0]._id;
    // }
  }

  if (this.isCovidTestEnabled) {
    this.appointment.meta = this.appointment.meta || {};
    this.appointment.meta.covidResult = this.appointment.meta.covidResult || 'negative';
    this.appointment.meta.selfPay = this.appointment.meta.selfPay || false;
    this.checkTestType();
  }

  // set sendNotifications
  if (this.sendNotificationEnabled) {
    this.appointment.sendNotifications = true;
  }

  // get earliest open hour
  let startTime = 360;

  office.openingHours.regular.forEach((day) => {
    if (day.open && day.times && day.times.length) {
      day.times.forEach((time) => {
        startTime = Math.min(startTime, time.start);
      });
    }
  });

  this.startTime = startTime;
  this.selectServicesForEmployee(this.appointment.object._id);
  this.recurringSettingsWatcher = this.watchRecurringSettings();
};

CustomerAppointmentModalCtrl.prototype = new AppointmentModalBaseCtrl();


CustomerAppointmentModalCtrl.prototype.selectServicesForEmployee = function (employeeId) {
  this.employeeServices = _.filter(this.services, function (service) {
    if (service.resources.employee.indexOf(employeeId) > -1) {
      return true;
    } else {
      return false;
    }
  });
};

CustomerAppointmentModalCtrl.prototype.currentServiceChanged = function () {
  if (this.currentService) {
    this.selectService(this.currentService);
    this.currentService = null;
  }
  this.checkTestType();
};


/**
 * calc the duration and overall price
 *
 * @param {Boolean} recalcDuration
 */
CustomerAppointmentModalCtrl.prototype.calcPriceAndDuration = function (recalcDuration) {
  var self = this;
  this.appointment.price = 0;
  this.duration = 0;

  _.forEach(this.appointment.services, function (service) {
    if (service.price) {
      self.appointment.price += _.toNumber(service.price);
    }

    self.duration += service.duration;
  });

  this.duration = this.duration === 0 ? 30 : this.duration;

  if (recalcDuration) {
    self.appointment.end = moment(self.appointment.start).add(self.duration, 'minutes').toDate();
  }
};


/**
 * add a service to appointment
 */
CustomerAppointmentModalCtrl.prototype.selectService = function ($item) {
  var selectedService = {
    _id: $item._id,
    name: $item.name,
    duration: $item.duration,
    price: $item.price,
    shorthand: $item.shorthand
  };

  this.appointment.services.push(selectedService);
  this.calcPriceAndDuration(true);
  this.service = '';

  if (this.resources.rooms &&
    this.resources.rooms.length &&
    !this.appointment.room &&
    $item.resources && $item.resources.room && $item.resources.room.length) {
    this.appointment.room = $item.resources.room[0];
  }
};


/**
 * remove service from appointment
 * @param {Number} index
 */
CustomerAppointmentModalCtrl.prototype.removeService = function (index) {
  this.appointment.services.splice(index, 1);
  this.calcPriceAndDuration();
  this.checkTestType();
};


/**
 * move a service up or down in service list
 * @param {Number} index
 * @param {Number} direction
 */
CustomerAppointmentModalCtrl.prototype.moveService = function (index, direction) {
  if (direction > 0) {
    if (index > 0) {
      this.appointment.services.move(index, (index - 1));
    }
  } else {
    if (index < (this.appointment.services.length - 1)) {
      this.appointment.services.move(index, (index + 1));
    }
  }
  this.checkTestType();
};

CustomerAppointmentModalCtrl.prototype.openCashModal = function () {
  var cashModalData = {
    appointment: this.appointment._id,
    positions: [],
    total: 0,
    cashed: new Date()
  };

  if (this.appointment.customer && this.appointment.customer._id) {
    cashModalData.customer = this.appointment.customer._id;
  }

  this.appointment.services.forEach((service) => {
    const serviceDocument = this.services.find((s) => s._id === service._id);
    const defaultTax = serviceDocument && serviceDocument.accounting && serviceDocument.accounting.tax !== undefined ? serviceDocument.accounting.tax : this.taxes[0].value;

    cashModalData.positions.push({
      _id: service._id,
      name: service.name,
      price: service.price || 0,
      quantity: 1,
      tax: defaultTax
    });
  });

  this.close();
  this._ModalService.openCashModal(cashModalData, function () {
  });
};

CustomerAppointmentModalCtrl.prototype.accountingTabClicked = function () {
  var cashModalData = {
    appointment: this.appointment._id,
    positions: [],
    total: 0,
    cashed: new Date()
  };

  if (this.appointment.customer && this.appointment.customer._id) {
    cashModalData.customer = this.appointment.customer._id;
  }

  this.appointment.services.forEach((service) => {
    const serviceDocument = this.services.find((s) => s._id === service._id);
    const defaultTax = serviceDocument && serviceDocument.accounting && serviceDocument.accounting.tax !== undefined ? serviceDocument.accounting.tax : this.taxes[0].value;

    cashModalData.positions.push({
      _id: service._id,
      name: service.name,
      price: service.price || 0,
      quantity: 1,
      tax: defaultTax
    });
  });

  if (!this.cashBookEntry && this.appointment._id) {
    this.rootScope.showLoadingIndicator = true;
    this._AppointmentDA
      .getCashBookEntry(this.appointment._id)
      .then((response) => {
        this.cashBookEntry = response;
        this.cashBookEntryLoaded = true;
        this.rootScope.showLoadingIndicator = false;
        this.isCheckoutLoading = true;

        this._AccountingDA
          .getHelloCashCheckoutLink(cashModalData)
          .then((response) => {
            this.isCheckoutLoading = false;
            this.helloCashCheckoutUrl = response;
          })
          .catch((error) => {
            this.isCheckoutLoading = false;
          });
        //
      })
      .catch(() => {
        this.rootScope.showLoadingIndicator = false;
        this.cashBookEntryLoaded = true;
      });
  }
};

CustomerAppointmentModalCtrl.prototype.getErrorTranslation = function (errorKey) {
  const customer = this.customerLabel;
  const office = this.gettextCatalog.getString('Office');
  const errorTranslations = {
    'customer.email': `${this.gettextCatalog.getString('E-Mail')} (${customer})`,
    'customer.firstname': `${this.gettextCatalog.getString('Firstname')} (${customer})`,
    'customer.lastname': `${this.gettextCatalog.getString('Lastname')} (${customer})`,
    'customer.street': `${this.gettextCatalog.getString('Street')} (${customer})`,
    'customer.postal': `${this.gettextCatalog.getString('Postal')} (${customer})`,
    'customer.city': `${this.gettextCatalog.getString('City')} (${customer})`,
    'customer.birthday': `${this.gettextCatalog.getString('Birthday')} (${customer})`,
    'office.name': `${this.gettextCatalog.getString('Company name')} (${office})`,
    'office.address.street': `${this.gettextCatalog.getString('Street')} (${office})`,
    'office.address.postal': `${this.gettextCatalog.getString('Postal')} (${office})`,
    'office.address.city.name': `${this.gettextCatalog.getString('City')} (${office})`,
    'office.profile.contacts.email': `${this.gettextCatalog.getString('E-Mail')} (${office})`,
    'office.profile.contacts.phone': `${this.gettextCatalog.getString('Telephone')} (${office})`
  };

  return errorTranslations[errorKey] ? errorTranslations[errorKey] : errorKey;
};


CustomerAppointmentModalCtrl.prototype.openCashBook = function () {
  this._state.go('private.main.accountingCashBook', { date: moment(this.cashBookEntry.cashed).format('YYYY-MM-DD') });
};

CustomerAppointmentModalCtrl.prototype.openCWALegalModal = function () {
  this._ModalService.openCWALegalModal(angular.noop);
};

CustomerAppointmentModalCtrl.prototype.generateDocument = function (open) {
  this.isDocumentLoading = true;
  this.isDocumentError = false;
  this.documentInFuture = false;
  this.missingDocumentParams = [];

  // if(moment(this.appointment.start).subtract(15, 'minutes').isAfter(moment())) {
  //   this.isDocumentError = true;
  //   this.documentInFuture = true;
  // } else {
  this._DocumentDA
    .create(
      this.appointment._id,
      this.sendDocumentToCustomer,
      this.appointment.meta.covidResult === 'negative',
      this.sendDocumentToHealthDepartment,
      this.selectedTest,
      this.appointment.checks,
      this.appointment.meta.selfPay,
      false,
      null,
      'quickTest',
      {}
    )
    .then((response) => {
      this.isDocumentLoading = false;
      this.appointment.meta.resultCreated = new Date();
      this.appointment.meta.document = response._id;
      this.appointment.meta.test = this.selectedTest;
      if (this.sendDocumentToCustomer || this.sendDocumentToHealthDepartment) {
        this._Notification.addSuccessMessage(this.gettextCatalog.getString('Document created and send via E-Mail.'));
        this.appointment.meta.resultSent = new Date();
        this.save();
        if (open) {
          window.open(`/api/v2/document/${response._id}?original=true`);
        }
      } else {
        if (open) {
          window.open(`/api/v2/document/${response._id}?original=true`);
        }
        this._Notification.addSuccessMessage(this.gettextCatalog.getString('Document created and saved in customer profile.'));
        this.save(false);
      }
    })
    .catch((error) => {
      this.missingDocumentParams = _.get(error, 'data.error.missingParams', []);
      this.isDocumentLoading = false;
      this.isDocumentError = true;
      this._Notification.addErrorMessage(this.gettextCatalog.getString('An Error occured!'));
    });
  // }
};

CustomerAppointmentModalCtrl.prototype.checkTestType = function () {
  const oldTestType = this.testType;
  this.testType = '';

  if (this.appointment.services && this.appointment.services.length) {
    const officeService = getServiceFromOffice(this.office, this.appointment.services[0]._id);

    if (officeService && officeService.covid.testType) {
      this.testType = officeService.covid.testType;
    }
  }

  // delete test reasons
  if(this.testType !== oldTestType) {
    delete this.appointment.testReason;
    delete this.appointment.testReason2;
    delete this.appointment.stayReason;
    delete this.appointment.facilityType;
  }
};

module.exports = CustomerAppointmentModalCtrl;

'use strict';

module.exports = function (ModalService, ResourceDA, OfficeDA, $stateParams) {

  return {
    restrict: 'E',
    templateUrl: 'https://cdn.termin2go.com/app/app/modules/calendar/CalendarListView.html?v=3.82.0',
    scope: {
      dates: '='
    },
    controller: function ($scope) {
      var resources;
      var testReasons = {};
      $scope.allResourceView = $stateParams.resource.includes('all-employees');

      ResourceDA
        .list()
        .then(function (response) {
          resources = response.resources;
        });

      OfficeDA
        .get()
        .then((office) => {
          $scope.template = _.get(office, 'settings.calendar.customerAppointmentTemplate', 'appointmentCustomerDefault');
          testReasons.testReasons = [
            {
              id: 9,
              label: 'Angehörige von Infizierten (kostenfrei)'
            },
            {
              id: 10,
              label: 'Pflegende Angehörige sowie Menschen mit Behinderungen und deren Betreuungskräfte (kostenfrei)'
            },
            {
              id: 11,
              label: 'Besucher von Kliniken und Pflegeheimen (kostenfrei)'
            },
            {
              id: 12,
              label: 'Menschen die sich nicht impfen lassen können (kostenfrei)'
            },
            {
              id: 13,
              label: 'Frauen zu Beginn der Schwangerschaft (kostenfrei)'
            },
            {
              id: 14,
              label: 'Kinder bis fünf Jahre (kostenfrei)'
            },
            {
              id: 15,
              label: 'Teilnahme an klinischen Studien zu Medikamenten gegen COVID-19 (kostenfrei)'
            },
            {
              id: 16,
              label: 'Freitestung (kostenfrei)'
            },
            {
              id: 19,
              label: 'Leistungsberechtigte, die im Rahmen eines Persönlichen Budgets nach dem § 29 SGB IX Personen beschäftigen, sowie Personen, die bei Leistungsberechtigten im Rahmen eines Persönlichen Budgets beschäftigt sind (kostenfrei)'
            },
            // {
            //   id: 17,
            //   label: 'Voraufenthalt in Virusvariantengebieten'
            // },
            {
              id: 18,
              label: 'Bürgertestung (3,00€ Eigenbeteiligung nur für berechtigten Personengruppe)'
            },
            {
              id: 99,
              label: `keine der genannten Gründe (${office.settings.covidTest.prices.rapid}€)`
            },
          ];
          testReasons.testReasons2 = [
            {
              id: 0,
              label: 'Ich werde am Tag, an dem die Testung erfolgt eine Veranstaltung in einem Innenraum besuchen.'
            },
            {
              id: 1,
              label: 'Ich werde am Tag, an dem die Testung erfolgt Kontakt zu einer Person haben, die das 60. Lebensjahr vollendet hat.'
            },
            {
              id: 2,
              label: 'Ich werde am Tag, an dem die Testung erfolgt zu einer Person Kontakt haben, die ein hohes Risiko hat, schwer zu erkranken.'
            },
            {
              id: 3,
              label: 'Aktuell wird in meiner Corona-Warn-App der Status "erhöhtes Risiko" angezeigt.'
            },
          ];
          testReasons.testReasonsPCR = [
            {
              id: 1,
              label: 'Kontaktperson oder Corona-Warn-App (§ 2 TestV)'
            },
            {
              id: 3,
              label: 'Ausbruchsgeschehen (§ 3 TestV)'
            },
            {
              id: 2,
              label: 'Bürgertestung (§ 4a TestV)'
            },
            {
              id: 4,
              label: 'Verhütung der Verbreitung (§ 4 Abs. 1 Nr. 1 und 2 TestV)'
            },
            {
              id: 8,
              label: 'bestätigende Testung (§ 4b  Satz 1 und 2 TestV)'
            },
            {
              id: 9,
              label: 'variantenspezifische Testung (§ 4b  Satz 3)'
            }
          ];
          testReasons.stayReasons = [
            {
              id: 0,
              label: 'keine Angabe'
            },
            {
              id: 1,
              label: 'betreut/untergebracht'
            },
            {
              id: 2,
              label: 'Tätigkeit in Einrichtung'
            }
          ];
          testReasons.facilityTypes = [
            {
              id: 0,
              label: 'keine Angabe'
            },
            {
              id: 1,
              label: 'Medizinische Einrichtung'
            },
            {
              id: 2,
              label: 'Gemeinschaftseinrichtung (z.B. Kitas, Schulen)'
            },
            {
              id: 3,
              label: 'Pflege- und andere Wohneinrichtung'
            },
            {
              id: 4,
              label: 'Sonstige Einrichtungen'
            }
          ];
        });

      if (!$scope.dates[ 0 ].resources || !$scope.dates[ 0 ].resources.length) {
        $scope.noResourced = true;
      }

      $scope.openAppointment = function (appointment) {
        ModalService.openAppointmentModal(appointment);
      };

      $scope.getAppointments = function (resources) {
        let appointments = [];

        resources.forEach(resource => {
          resource.appointments = resource.appointments.map((appointment) => {
            appointment.resourceFullName = resource.fullname;
            return appointment;
          });
          appointments = appointments.concat(resource.appointments);
        });

        appointments.sort(function(appointment1, appointment2) {
          return new Date(appointment1.preparationStart) - new Date(appointment2.preparationStart);
        });

        return appointments;
      }

      $scope.getRoomName = function (roomId) {
        const room = resources.find(resource => resource._id === roomId)

        return room ? room.name : '';
      };

      $scope.dateHaveAppointments = function (date) {
        var count = 0;

        _.forEach(date.resources, function (resource) {
          count += _.size(resource.appointments);
        });

        return count > 0;
      };

      $scope.getAppointmentCSSClass = function (appointment) {
        const classes = {
          'list-appointment__time--red': $scope.template === 'appointmentCustomerPharmacy' && (!appointment.meta || !(appointment.meta.resultSent || appointment.meta.resultCreated)) && appointment.type === 'customer',
          'list-appointment__time--grey': appointment.type === 'private'
        };
        return classes;
      };

      $scope.getReasonString = function (key, value) {
        const item = testReasons[key].find(i => i.id === value);

        return item ? item.label : '';
      };
    }
  };
};


'use strict';

var profileModule = angular.module('t2g.profile', [
    'gettext',
    'ngFileUpload',
    'ui.router',
    require('t2g.common.data-access.countryConfig').name,
    require('t2g.common.services.geoCodeService').name,
    require('t2g.common.services.notificationService').name
]);

module.exports = profileModule;

profileModule.controller('ProfileCtrl', require('./profileCtrl'));

profileModule.config(function($stateProvider) {
    $stateProvider
        .state('private.main.profile', {
            url: '/profile',
            title: 'Profile',
            templateUrl: 'https://cdn.termin2go.com/app/app/modules/profile/ProfileView.html?v=3.82.0',
            controller: 'ProfileCtrl',
            resolve: {
                officeResolve: function(OfficeDA) {
                    return OfficeDA.get();
                },
                industryResolve: function(IndustryDA) {
                  return IndustryDA.list();
                },
                countryResolve: function(CountryConfig) {
                    return CountryConfig.getCountries();
                }
            }
        });

});

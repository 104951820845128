var logModule = angular.module('t2g.log', [
  'gettext',
  'ui.router',
  require('t2g.common.services.modalService').name,
  require('t2g.common.services.notificationService').name

]);

module.exports = logModule;

logModule.controller('LogListCtrl', require('./LogListCtrl'));

logModule.config(function ($stateProvider) {
  $stateProvider
    .state('private.main.log', {
      url: '/log?documentId',
      title: 'Log',
      templateUrl: 'https://cdn.termin2go.com/app/app/modules/log/LogListView.html?v=3.82.0',
      controller: 'LogListCtrl as LogListCtrl',
      resolve: {
        items($stateParams, LogDA) {
          const filter = {}

          if ($stateParams.documentId) {
            filter.documentId = $stateParams.documentId;
          }

          return LogDA.list(filter);
        },
        employees(ResourceDA) {
          return ResourceDA
            .list('employee')
            .then(resources => _.filter(resources.resources, { type: 'employee' }));
        },
        users: function (UserDA) {
          return UserDA.list();
        }
      }
    });
});

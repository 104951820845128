'use strict';

module.exports = function ($timeout) {
    return {
        restrict: 'E',
        templateUrl: 'https://cdn.termin2go.com/app/app/modules/calendar/CalendarView.html?v=3.82.0',
        replace: true,
        scope: {
            model: '=',
            office: '=',
            resources: '='
        },
        controller: 'CalendarCtrl as CalendarCtrl',
        link: function ($scope, $element) {
            function setCalendarWidth() {
                var scrollWrapper = $element.find('.calendar__scroll-wrapper');
                var headerWidth= $element.find('.calendar__header')[0].getBoundingClientRect().width;
                scrollWrapper.css('width', headerWidth + 'px');
            }

            function setHeaderPadding(){
                var calendarHeader = $element.find('.calendar__header');
                var calendarBody = $element.find('.calendar__body');
                var calendarDates = $element.find('.calendar__header__row--dates');
                var calendarDatesRight;
                var calendarBodyRight;
                var scrollBarWidth;

                if (calendarHeader.length > 0 && calendarBody.length > 0 && calendarDates.length > 0) {
                    calendarDatesRight = calendarDates[0].getBoundingClientRect().right;
                    calendarBodyRight = calendarBody[0].getBoundingClientRect().right;
                    scrollBarWidth = calendarDatesRight - calendarBodyRight;
                    calendarHeader.css('padding-right', scrollBarWidth + 'px');
                }
            }

            $scope.$on('t2g:window:resize', function(){
                setCalendarWidth();
                setHeaderPadding();
            });

            $timeout(setCalendarWidth);
            $timeout(setHeaderPadding);
        }
    };
};

var MessageCtrl = function ($scope,
                            $state,
                            gettextCatalog,
                            Message,
                            messageResolve,
                            BookingDA,
                            ModalService,
                            officeLabelResolve,
                            Notification) {
    this.scope = $scope;
    this.state = $state;
    this.office = officeLabelResolve.officeResolve;
    this.industrySpecificResolve = officeLabelResolve.industrySpecificResolve;
    this.gettextCatalog = gettextCatalog;
    this.Message = Message;
    this.Notification = Notification;
    this.BookingDA = BookingDA;
    this.ModalService = ModalService;
    this.message = messageResolve;
    this.loadingAccept = false;
    this.loadingDecline = false;
    this.customerAddress = '';
    this.labels = {
        customer: gettextCatalog.getString(this.industrySpecificResolve.customer.toLowerCase()),
        customers: gettextCatalog.getString(this.industrySpecificResolve.customers.toLowerCase())
    };
    this.from = {email: '', name: '', customerId: ''};
    this.to = {email: '', name: '', customerId: ''};

    let customer = this.getCustomer();

    if (this.message.box === 'inbox') {
        this.to.email = this.message.to;
        this.from.email = customer.email;
        this.from.name = customer.fullname;
        this.from.customerId = customer.customerId;
    } else if (this.message.box === 'outbox') {
        this.from.email = this.message.from;

        if (customer) {
            this.to.email = customer.email;
            this.to.name = customer.fullname;
            this.to.customerId = customer.customerId;
        } else {
            this.to.email = this.message.to;
        }
    }

    if (_.get(this.message, 'booking.customer.street')) {
        this.customerAddress = [
            this.message.booking.customer.street,
            `${this.message.booking.customer.postal}  ${this.message.booking.customer.city}`,
        ].join(', ');
    }
};

MessageCtrl.prototype.getCustomer = function () {
    if (this.message.customer) {
        return {
            fullname: `${this.message.customer.firstname} ${this.message.customer.lastname}`,
            customerId: this.message.customer._id,
            email: this.message.customer.email
        };
    }

    if (this.message.booking && this.message.booking.customer) {
        return {
            fullname: `${this.message.booking.customer.firstname} ${this.message.booking.customer.lastname}`,
            customerId: this.message.booking.customer._id,
            email: this.message.booking.customer.email,
        };
    }
};

MessageCtrl.prototype.goBack = function () {
    // TODO go back on mobile, nothing on desktop
    this.state.go('private.main.messages', {box: this.message.box});
};

MessageCtrl.prototype.delete = function () {
    var self = this;

    this.Message.delete(this.message).then(
        function () {
            self.Notification.addSuccessMessage(self.gettextCatalog.getString('Message deleted'));
            self.goBack();

            if (self.message.topic === 'booking') {
                self.scope.$emit('Booking:delete');
            }
        },
        function () {
            self.Notification.addErrorMessage(self.gettextCatalog.getString('Cant remove message'), true);
        }
    );
};

MessageCtrl.prototype.openAppointment = function () {
    this.message.appointment.start = new Date(this.message.appointment.start);
    this.message.appointment.end = new Date(this.message.appointment.end);
    console.log(this.message.appointment);

    this.ModalService.openAppointmentModal(this.message.appointment);
};

MessageCtrl.prototype.accept = function () {
    const self = this;
    this.loadingAccept = true;

    this.BookingDA
        .accept(this.message.booking)
        .then(function () {
            self.loadingAccept = false;
            self.Notification.addSuccessMessage(self.gettextCatalog.getString('Booking accepted'));
            window.location.reload();
        })
        .catch(function () {
            self.loadingAccept = false;
            self.Notification.addErrorMessage(self.gettextCatalog.getString('Cant accept booking'));
        });
};

MessageCtrl.prototype.decline = function () {
    const self = this;
    this.loadingDecline = true;

    this.BookingDA
        .decline(this.message.booking)
        .then(function () {
                self.Notification.addSuccessMessage(self.gettextCatalog.getString('Booking declined'));
                self.loadingDecline = false;
                window.location.reload();
            },
            function () {
                self.loadingDecline = false;
                self.Notification.addErrorMessage(self.gettextCatalog.getString('Cant accept booking'));
            }
        );
};

MessageCtrl.prototype.disableClick = function () {
    return false;
};


module.exports = MessageCtrl;
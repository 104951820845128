'use strict';

var privacyModule = angular.module('t2g.data-privacy', [
    'gettext',
    'ui.router',
    require('t2g.common.services.notificationService').name
]);

module.exports = privacyModule;

privacyModule.controller('PrivacyCtrl', require('./PrivacyCtrl'));

privacyModule.config(function ($stateProvider) {
    $stateProvider
      .state('private.main.data-privacy', {
          url: '/data-privacy',
          title: 'Data Privacy',
          templateUrl: 'https://cdn.termin2go.com/app/app/modules/privacy/PrivacyView.html?v=3.82.0',
          controller: 'PrivacyCtrl as PrivacyCtrl',
          resolve: {
              office: function(OfficeDA) {
                  return OfficeDA.get();
              }
          }
      });
});

